import { Fragment, MouseEvent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ArrowDown } from '@/icons';
import clsx from 'clsx';
import { isDevelopment } from '@/utils/isDevelopment';
import { Link } from '../CustomLink';
import { Warning } from '../Warning';
const activeLinkStyles = 'underline decoration-bc-green underline-offset-4';
type Props = {
  className?: string;
  color: 'light' | 'dark';
  links?: {
    text?: string;
    uppercase?: boolean;
    divider?: boolean;
    forceActiveLink?: boolean;
    link?: {
      href: string;
      onClick(event: MouseEvent): void;
      target?: '_self' | '_blank';
    };
  }[];
};
export function NavigationLinks({
  className,
  color,
  links
}: Props) {
  const {
    asPath
  } = useRouter();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [activeLink, setActiveLink] = useState<string | null>(null);
  useEffect(() => {
    const forcedActiveLink = links?.find(({
      forceActiveLink
    }) => forceActiveLink);
    if (forcedActiveLink && forcedActiveLink.link?.href) {
      setActiveLink(forcedActiveLink.link?.href);
      return;
    }
    const activeLink = links?.find(({
      link
    }) => {
      if (!link?.href) return false;
      return asPath === (link.href.endsWith('/') ? link.href : `${link.href}/`);
    })?.link?.href;
    if (activeLink) {
      setActiveLink(activeLink);
      return;
    }
    setActiveLink(null);
  }, [asPath, links]);
  if (!links || links.length === 0) {
    if (isDevelopment()) {
      return <Warning className={className}>No links provided</Warning>;
    }
    return null;
  }
  const activeLinkText = links?.find(({
    link
  }) => link?.href === activeLink)?.text;
  return <div className={clsx(className, 'font-medium')} data-sentry-component="NavigationLinks" data-sentry-source-file="NavigationLinks.tsx">
			<button onClick={() => setMobileNavOpen(prev => !prev)} className="flex w-full items-center lg:hidden py-2">
				<span className="sr-only">{mobileNavOpen ? 'close menu' : 'open menu'}</span>
				<span className={clsx('text-xs', color === 'light' && 'text-gray-400', color === 'dark' && 'text-white', !activeLinkText && 'h-[22px]', activeLinkStyles)}>
					{activeLinkText}
				</span>
				<ArrowDown className={clsx('w-3 ml-auto transition duration-150', mobileNavOpen && 'rotate-180', color === 'light' && 'text-gray-400', color === 'dark' && 'text-white')} data-sentry-element="ArrowDown" data-sentry-source-file="NavigationLinks.tsx" />
			</button>

			<nav className={clsx('flex lg:items-center gap-y-1 max-lg:flex-col', !mobileNavOpen && 'max-lg:hidden')}>
				{links?.map(({
        link,
        text,
        uppercase,
        divider
      }, i) => {
        if (!text) return null;
        if (!link?.href || link.href === '#') return <Fragment key={i}>
								<span className={clsx('text-xs max-lg:hidden leading-4 py-2 px-3', color === 'light' && 'text-gray-700', color === 'dark' && 'text-white/70', uppercase && 'uppercase')}>
									{text}
								</span>

								{divider && <div className="max-lg:hidden w-px h-5 bg-gray-800 mt-2 mb-1 mx-3" aria-hidden />}
							</Fragment>;
        return <Link href={link.href} className={clsx('text-xs py-2 lg:px-3 leading-4', color === 'light' && 'text-gray-700 hover:text-gray-400', color === 'dark' && 'text-white/70 hover:text-white', link.href === activeLink && `${activeLinkStyles} max-lg:hidden ${color === 'light' ? '!text-gray-400' : '!text-white'}`, uppercase && 'uppercase')} key={i}>
							{text}
						</Link>;
      })}
			</nav>
		</div>;
}